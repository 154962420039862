import { createStore } from 'vuex'

export default createStore({
  state: {
    resumeFile: null,
    cvFile: null
  },
  getters: {
  },
  mutations: {
    setResumeFile(state, file) {
      state.resumeFile = file;
    },
    setCvFile(state, file) {
      state.cvFile = file;
    }
  },
  actions: {
  },
  modules: {
  }
})
