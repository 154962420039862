import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/service/digital',
    name: 'digital',
    component: () => import(/* webpackChunkName: "digital" */ '../views/Service1View.vue'),
  },
  {
    path: '/service/process',
    name: 'process',
    component: () => import(/* webpackChunkName: "process" */ '../views/Service2View.vue'),
  },
  {
    path: '/service/simulation',
    name: 'simulation',
    component: () => import(/* webpackChunkName: "simulation" */ '../views/Service3View.vue'),
  },
  {
    path: '/service/innovation',
    name: 'innovation',
    component: () => import(/* webpackChunkName: "innovation" */ '../views/Service4View.vue'),
  },
  {
    path: '/seminar',
    name: 'seminar',
    component: () => import(/* webpackChunkName: "seminar" */ '../views/SeminarView.vue'),
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: () => import(/* webpackChunkName: "recruit" */ '../views/RecruitView.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/PrivacyView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactFormView.vue'),
    props: true
  },
  {
    path: '/contact-confirm',
    name: 'contact-confirm',
    component: () => import(/* webpackChunkName: "contact-confirm" */ '../views/ContactFormConfirmView.vue'),
    props: true
  },
  {
    path: '/contact-completed',
    name: 'contact-completed',
    component: () => import(/* webpackChunkName: "contact-completed" */ '../views/ContactFormCompletedView.vue'),
    props: true
  },
  {
    path: '/entry',
    name: 'entry',
    component: () => import(/* webpackChunkName: "entry" */ '../views/EntryFormView.vue'),
    props: true
  },
  {
    path: '/entry-confirm',
    name: 'entry-confirm',
    component: () => import(/* webpackChunkName: "entry-confirm" */ '../views/EntryFormConfirmView.vue'),
    props: true
  },
  {
    path: '/entry-completed',
    name: 'entry-completed',
    component: () => import(/* webpackChunkName: "entry-completed" */ '../views/EntryFormCompletedView.vue'),
    props: true
  },
  {
    path: '/sustainability',
    name: 'sustainability',
    component: () => import(/* webpackChunkName: "sustainability" */ '../views/SustainabilityView.vue'),
    props: true
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import(/* webpackChunkName: "activity" */ '../views/activityView.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
