<template>
  <div class="home">
    <MainHeader />
    <div class="content">
      <div class="main-video-wapper">
        <video width="1920" height="1000" v-on:canplay="hideLoader" playsinline autoplay muted loop>
          <source src="../assets/videos/top.mp4" type="video/mp4">
          動画を再生するには、videoタグをサポートしたブラウザが必要です。
        </video>
        <div v-show="isLoading" class="loader loader-default is-active"></div>
      </div>
      <br> <br>
      </div>
      
      <div class="inner philosophy-content">        
        <p>Mission</p>
        <p>スマートなプロセスで<br>
             未来をつくる</p>
        <p>私たちは、開発プロセスの再構築とビジネスを企画することで、<br>
日本の製造業の持続的な発展に貢献します。</p>
        <MediumButton 
          class="btn-philosophy-more"
          image-name="btn-txt-more.png"
          image-width="41"
          image-height="25"
          text="MORE"
          linkPath="/about"
        />
      </div>
      <br>
      <h2 class="tit-bg-service"><img src="../assets/images/tit-top-service.png" alt="SERVICE" width="185" height="75"></h2>
      <div class="inner" id="videointro">
        <h2><img class="sub-video-tit" src="../assets/images/vid-tit-jp.png" alt="JAPANESE" width="140" height="auto"></h2>
        <video class="sub-video" muted controls poster="../assets/images/thumbnail-SP-jp.png">
          <source src="../assets/videos/SP_concept_jp.mp4?ver=1.0.0" type="video/mp4" >
        </video>
      </div>
      <div class="inner">
        <h2><img  class="sub-video-tit" src="../assets/images/vid-tit-en.png" alt="ENGLISH" width="140" height="auto"></h2>
        <video class="sub-video" muted controls poster="../assets/images/thumbnail-SP-en.png">
          <source src="../assets/videos/SP_concept_en.mp4?ver=1.0.0" type="video/mp4" >
      </video>
      </div>
      <br>
      <div class="inner service-content">
        <p>製造業の開発領域に関するDXを加速するため、<br>
開発デジタル化戦略企画、開発プロセス設計<!-- 、シミュレーションプロセス設計 -->に関する各種サービスを提供します。<br>
開発現場に最新のプロセスとソリューションを適用し、開発DXによるイノベーションに貢献します。</p>
        <ul>
          <router-link to="/service/digital">
            <li>
                <p><img src="../assets/images/tit-service-1.png" alt="SERVICE #1" width="48" height="15"></p>
                <h3>開発デジタル化<br>
戦略企画</h3>
                <p>開発プロセス全体を把握し、目指すべき<br>
グランドデザインを策定。プロセス設計と<br>
デジタル技術を活用し、組織全体の<br>
開発DX実現に貢献します。<br>
</p>
            </li>
          </router-link>
          <router-link to="/service/process">
            <li>
                <p><img src="../assets/images/tit-service-2.png" alt="SERVICE #2" width="48" height="15"></p>
                <h3>開発プロセス設計</h3>
                <p>現状プロセスの分析、評価をもとに、<br>
デジタル技術を活用した<br>
To-Beプロセスを製品企画から<br>
検証段階まで設計します。</p>
            </li>
          </router-link>
          <!--  
          <router-link to="/service/simulation">
            <li>
                <p><img src="../assets/images/tit-service-3.png" alt="SERVICE #3" width="48" height="15"></p>
                <h3>シミュレーション<br>
                プロセス設計</h3>
                <p>開発プロセス設計と連携可能な<br>
シミュレーションプロセスを<br>
開発することで、効率的で多角的な<br>
シミュレーションを行います。</p>
            </li>
          </router-link>
          -->
          <router-link to="/service/innovation">
            <li>
                <p><img src="../assets/images/tit-service-3.png" alt="SERVICE #4" width="48" height="15"></p>
                <h3>イノベーション<br>
                プロセス展開</h3>
                <p>B2Cでの最新トレンドや世の中の最新動向を<br>
取り入れた、ノベーティブなプロセスの<br>適用・展開をご支援します。<br>
</p>
            </li>
          </router-link>
        </ul>
      </div>
      <h2 class="tit-bg-recruit"><img src="../assets/images/tit-top-recruit.png" alt="RECRUIT" width="188" height="75"></h2>
      <div class="inner recruit-content">
        <p>私たちは、製造業の開発領域に関するDXを支援するため、コンサルタント、エンジニア、<br>
その他、多様な専門性を持ったメンバーで、業務に取り組んでいます。<br>
私たちの経営理念に共感し、製造業のDXに貢献したい方のご応募をお待ちしています。</p>
        <ul>
          <li>
            <h3>
              開発デジタル化戦略企画<br>
コンサルタント
            </h3>
            <p>製品の開発プロセス全体を把握し、開発DX<br>
を実現するグランドデザインを策定します。<br>
また、グランドデザイン実現までの推進企画<br>
を通じて、開発全体のDXを支援します。</p>
          </li>
          <li>
            <h3>
              開発プロセス設計<br>
              エンジニア
            </h3>
            <p>上流設計を意識したTo-Beプロセスの設計、<br>
            プロセスの標準化を推進し、<br>
            開発プロセスの整流化、<br>
            開発のフロントローディングに貢献します。</p>
          </li>
          <!--  
          <li>
            <h3>
              シミュレーションプロセス<br>
              設計エンジニア
            </h3>
            <p>シミュレーションプロセスを開発し、<br>
多角的な視点で定量的データを<br>
蓄積するとともに、<br>
製品性能の向上を実現します。</p>
          </li>
          -->
        </ul>
        <MediumButton
          class="btn-recruit-more"
          image-name="btn-txt-more.png"
          image-width="41"
          image-height="25"
          text="MORE"
          linkPath="/recruit"
        />
      </div>

      <div class="inner news-content">
        <h2><img src="../assets/images/tit-news.png" alt="NEWS" width="125" height="50"></h2>
        <ul class="inner news-content-url">
          <li>
            <span>
            【2024.03.05 Tue】<br>
              <a href="https://blog.smart-process.co.jp/wp/2024/03/05/%e7%ac%ac3%e5%9b%9e%e3%80%80%e6%97%a5%e6%9c%ac%e3%81%ae%e8%a3%bd%e9%80%a0%e6%a5%ad%e3%81%ae%e6%9c%aa%e6%9d%a5%e3%82%92%e8%aa%9e%e3%82%8b%e4%bc%9a%e3%80%80%e3%83%bc%e6%9c%aa%e6%9d%a5%e3%82%92%e7%af%89/">
              ブログ更新_Smart Process 主催による第3回 [日本の製造業の未来を語る会：未来を築くディスカッション 共創と未来]
              </a><br>
            </span><br>
            <span>
              【年末年始休業のお知らせ】<br> 
              誠に勝手ながら、弊社では下記の期間につきまして年末年始休業とさせていただきます。<br>
              期間中はご不便ご迷惑をおかけ致しますが何卒ご了承くださいますようお願い申し上げます。<br>
              年末年始休業期間&nbsp;:&nbsp;2023年12月29日(金)&nbsp;～&nbsp;2024年1月8日(月)<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02ZnqqRrn9nhpeeNw5cNfN9qWZhzWcgTaDJrtpJguxsz4Z9Z9B6nTq75XGZBDneZi7l&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7144882085625835520">
              LinkedIn</a>
            </span><br>
            <span>
              【2023.12.12 tue】<br>
              <a href="https://blog.smart-process.co.jp/wp/2023/12/12/%e3%80%8c%e5%83%8d%e3%81%8d%e3%82%84%e3%81%99%e3%81%84%e8%81%b7%e5%a0%b4%e7%92%b0%e5%a2%83%e3%81%a5%e3%81%8f%e3%82%8a%e6%8e%a8%e9%80%b2%e3%83%97%e3%83%a9%e3%83%b3%e3%80%80%ef%bd%82%e3%82%b3%e3%83%bc/">
                ブログ更新_「働きやすい職場環境づくり推進プラン Ｂコース①【介護と仕事の両立推進事業】」を推進しました
              </a><br>
            </span><br>
            <span>
              【2023.09.15 fri】<br>
              <a href="http://blog.smart-process.co.jp/wp/2023/09/15/%e7%ac%ac2%e5%9b%9e%e6%97%a5%e6%9c%ac%e3%81%ae%e8%a3%bd%e9%80%a0%e6%a5%ad%e3%81%ae%e6%9c%aa%e6%9d%a5%e3%82%92%e8%aa%9e%e3%82%8b%e4%bc%9a%ef%bc%9a%e3%83%a2%e3%83%8e%e3%81%a5%e3%81%8f%e3%82%8a%e3%81%ae/">
              ブログ更新_Smart Process 主催による第2回 [日本の製造業の未来を語る会：モノづくりの楽しさの再発見]
              </a><br>
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02aU6pwJmXW32RySBqtQ8kzRC6HJ3vkPBcF3eYKxshGLTtD1dg6o6P1C2AgLQpme18l&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7108257339467563008/">
              LinkedIn</a>
            </span><br>
            <span>
              【夏季休業のお知らせ】<br> 
              誠に勝手ながら、弊社では下記の期間につきまして夏季休業とさせていただきます。<br>
              期間中はご不便ご迷惑をおかけ致しますが、何卒ご了承くださいますようお願い申し上げます。<br>
              夏季休業期間&nbsp;:&nbsp;2023年08月12日(土)&nbsp;～&nbsp;08月20日(日)
            </span><br>
            <span>
              【2023.07.26 wed】<br> 
              <a href="http://blog.smart-process.co.jp/wp/2023/07/25/%e3%80%90smart-process%e3%80%91%e3%80%8c%e3%83%86%e3%83%ac%e3%83%af%e3%83%bc%e3%82%af%e6%9d%b1%e4%ba%ac%e3%83%ab%e3%83%bc%e3%83%ab%e3%80%8d%e5%ae%9f%e8%b7%b5%e4%bc%81%e6%a5%ad%e5%ae%a3%e8%a8%80/">
              ブログ更新_「テレワーク東京ルール」実践企業宣言に登録しました</a>
              <br>&nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid0a4A4rJJe1QcKzcKBTxNuntUFMYLxg6CJLzFYGUeTWKptH4nKnoaaH4zecT2iekp4l&id=100087517794324">
              Facebook</a>
            </span><br>
            <span>
              【2023.06.21 wed】<br>
              <a href="http://blog.smart-process.co.jp/wp/2023/06/21/%e3%80%90smart-process%e3%80%91-%ef%bc%bc%e5%8f%a1%e5%95%93%e5%a4%a7%e5%ad%a6%e5%ae%9f%e8%b7%b5%e6%95%99%e8%82%b2%e3%83%97%e3%83%a9%e3%83%83%e3%83%88%e3%83%95%e3%82%a9%e3%83%bc%e3%83%a0%e5%8d%94/">
              ブログ更新_「叡啓大学実践教育プラットフォーム協議会」に参画しました</a>
              <br>&nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02Z4ujLprqefxd7AAdrR8VK8v3fMWe2Zdh3i8bi4MNkDXSeSg3ASGDkf36wWQEiEvSl&id=100087517794324">
              Facebook</a>
            </span><br>
            <span>
              【2023.06.16 fri】<br> 
              <a href="#SNS">
              &nbsp;<img src="../assets/images/news-link-icon.png">
              HP更新_ホームページの下部にSmart Process公式SNSアカウント一覧を表示しました
              </a>
            </span><br>
            <span>
              【2023.06.12 mon】<br> 
              &nbsp;SNS更新_【記事紹介】「カーボンニュートラルにも多様性を」自動車メーカー集結、日本の技術を世界に発信<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid0qJdCx4oWBBgzNWFuYdd3cLF7JH98xzCgmgzJqWa4fPyKqikRRNooXAqTReAa3PJtl&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7073853330795474945/">
              LinkedIn</a>
            </span><br>
            <span>
              【2023.03.07 tue】<br> 
              <a href="http://blog.smart-process.co.jp/wp/2023/03/07/sdgs/">
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              ブログ更新_SmartProcessのSDGs宣言
              </a>
            </span><br>
            <span>
              【2023.03.06 mon】<br> 
              &nbsp;SNS更新_持続可能な開発目標（SDGs）に関する宣言の表明<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02WZPBQRH9CeUjjFmZ3D4HGjK5VLpwwdgYBPsqZpsGeTXxxw8qJreZV57krmEt18Hzl&id=100087517794324&__cft__[0]=AZVC3SWaWfrw3OkGTpa8mfFVZBIPYO8oCGM8xhiNNG5m-XxNhu-OIktyRi0dIOC_bRK1o3KTeOHLuB8CJ9RTPMUK4QxaF16s0vQ_gbUCHMjUlf-GFsQsV2VfqMBLjuSALOIm_ZGV6clqIO0mIWI1YGX64wSiGeo1yF0GNf2emF6j4_-NWb4tBZ5AGhwx0LOjMuVmiRat7sqmONLgu71fW43q&__tn__=%2CO%2CP-R">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7038314767505117184">
              LinkedIn</a>
            </span><br>
            <span>
              【2023.02.23 thurs】<br> 
              &nbsp;SNS更新_【記事紹介】渋谷のビームス店頭に「まさかの」加賀獅子頭、窮地の伝統工芸が東京で復活<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02WZPBQRH9CeUjjFmZ3D4HGjK5VLpwwdgYBPsqZpsGeTXxxw8qJreZV57krmEt18Hzl&id=100087517794324&__cft__[0]=AZVx_1P24lHUXFr7VF96BTeo0HMd8WeyPYlsxfYIQfIK0WO0OMNhqnhzv-x824JZ8QmgRBAPmZp_MC-H4khPa3oqDUNQAzXrrd6rEjZiuEAR7CLyp_AXoZ1vLGYbDKEz0HU&__tn__=%2CO%2CP-R">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7034442796589600768">
              LinkedIn</a>
            </span><br>
            <span>
              【2023.01.23 mon】<br> 
              &nbsp;SNS更新_【記事紹介】ヤマハ本社工場で見た最新鋭ダイキャストマシン!!100円硬貨の薄さで大物部品を生み出す実力メカ!!<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid0UCX4yjY6VLo2UXMdjLmQoTGJnFVfomfYKrdAUwvcEWEDPsPV8WCw2rDqhM6ezZt6l&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7023166216978534400">
              LinkedIn</a>
            </span><br>
            <span>
              【2023.01.17 tue】<br> 
              &nbsp;SNS更新_【記事紹介】1/16(月)16時「メタバース産業革命～デジタルツインとの融合が生み出す、2030年のビジネス～【各業界の最新動向・事例編】」無料オンラインセミナーを開催<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02JL3Cr8KLYVYsNwKYY9BRjbfVEnVfUchdjo8yXmrwfv5cZvETWvmRVKMGSMy7qt3xl&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7021358484906532864">
              LinkedIn</a>
            </span><br>
            <span>
              【2022.12.28 wed】<br> 
              &nbsp;SNS更新_【記事紹介】トヨタ自動車が「市民開発」力を強化してDX推進<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid0Vga36ufzT2P1ZsmM6axjKNsmh7iXjLo5UtWyHtKee5u74eQ1J9PeNsLQCb7xaXCQl&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7013816915982786560">
              LinkedIn</a>
            </span><br>
            <span>
              【2022.12.23 mon】<br> 
              &nbsp;SNS更新_年末年始の休業日についてのお知らせ<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid0fp7z2VnavHM1Bp3q3eWJ6oFjPxXqZt6RtA7kN6yASYRz2HHwUhaX3M3XrqGJFDzYl&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7011899753277927424">
              LinkedIn</a>
            </span><br>
            <span>
              【2022.11.22 tue】<br> 
              <a href="#videointro">
              &nbsp;<img src="../assets/images/news-link-icon.png">
              HP更新_サービス紹介動画を公開しました。
              </a>
            </span><br>
            <span>
              【2022.10.14 fri】<br>
              <a href="http://blog.smart-process.co.jp/wp/2022/11/16/workshop_1/">
              &nbsp;<img src="../assets/images/news-link-icon.png">
              ブログ更新_Smart Process 主催による第1回 「性能設計エンジニア  教育／ワークショップ」 を執り行いました。
              </a>
            </span>
          </li>
        </ul>
      </div><br><br><br>
  </div>
    <MainFooter />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import MainHeader from '@/components/common/MainHeader.vue';
import MainFooter from '@/components/common/MainFooter.vue';
import MediumButton from '@/components/common/MediumButton.vue';

export default defineComponent({
  components: {
    MainHeader,
    MainFooter,
    MediumButton
},
  setup() {
    const show = ref<boolean>(false);
    const isLoading = ref<boolean>(true);

    const hideLoader = () => {
      isLoading.value = false;
    }
    
    return {
      isLoading,
      hideLoader,
      show
    }
  }
});
</script>

<style scoped lang="stylus">
@import "@/assets/styles/css-loader.css"

.news-links
.main-video-wapper
  position relative
  width 100%

.sub-video
  width 860px
  height auto
  margin-left 15px
  margin-right 0
  padding 0 30px

.sub-video-tit
  margin-left 39px
  
.loader,
.loader-default:after
  position absolute
.loader
  width 100%
  padding-top 52.083333%

.loader.is-active
  background-color rgba(0, 0, 0, 0.5);
video
  width 100%
  height auto
  aspect-ratio 1920 / 1000
  display block
.inner
  width 940px
  margin 50px auto
  padding 0 20px

.philosophy-content.inner
  width 700px
  margin 100px auto
  
  p
    font-size 20px
    line-height 1.8
    &:nth-child(2)
      font-size 48px
      line-height 1.5
      margin 0.25em 0

.tit-bg-service
  position relative
  width 100%
  padding-top 500px
  background-image url(../assets/images/bg-top-service.jpg);
  background-size cover
  background-position center center
  background-repeat no-repeat
  background-color #ccc
  img
    position absolute
    top 10%
    left 5%

.tit-bg-recruit
  position relative
  width 100%
  padding-top 500px
  background-image url(../assets/images/bg-top-recruit.jpg);
  background-size cover
  background-position center center
  background-repeat no-repeat
  background-color #ccc
  img
    position absolute
    top 10%
    left 5%

.service-content
  overflow hidden
  margin 30px auto 80px
  > p
    font-weight bold
    font-size 21px
    margin 0 0 30px 0
  ul
    padding-inline-start 0

  a
    color #000
    li
      list-style none
      width 297px
      height 269px
      padding 20px
      background-image url(../assets/images/bg-btn-service.png);
      background-size cover
      float left
      transition all .1s ease-out
      &:hover
        opacity 0.5

    &:nth-child(1),
    &:nth-child(2)
      li
        margin-right 4px  

    p
      margin 0
      font-size 12px

    h3
      font-size 27px
      line-height 1.3
      margin 0
      margin-top 0.3em
      height 125px

.recruit-content
  overflow hidden
  margin 30px auto 140px
  > p
    font-weight bold
    font-size 21px
    margin 0 0 30px 0
  ul
    padding-inline-start 0

  li
    list-style none
    border solid 1px
    width 290px
    height 220px
    padding 20px
    float left

    &:nth-child(2)
      margin 0 15px

    p
      margin 0
      font-size 12px

  h3
    font-size 20px
    margin 0
    padding-bottom 2.3em
    span
      font-size 10px
      font-weight normal
      display block
      margin-top 0.3em

.link-content
  overflow hidden
  list-style none
  h2
    width 130px
    float left
    margin 0
  ul
    float left
    padding-inline-start 0
    margin 9px 0 9px 95px
  li
    float left
    &:nth-child(2)
      margin 0 20px

    a
      transition all .1s ease-out
      &:hover
        opacity 0.5

.news-content-url
  border 2px solid black
  padding-top 20px 
  padding-bottom 20px 
  padding-left 20px 
  padding-right 20px
  color black
  text-decoration none
  display flex
  flex-direction column
  width 899.989px
  height 605px
  box-shadow 0 0.5px 2px rgba(0,0,0,.8)
  &:hover
    opacity 1
  li
    list-style none
    float left
    padding 0
    font-size 125%
    padding-bottom 10px
    overflow-y scroll
    a
      color #000
      text-decoration none
      &:hover
        opacity 0.5
    img
      width 17px 
      height 17px
    span
      display block
      content "" 
      margin-bottom -12px
      margin-right 15px
      border-bottom 1px dashed gray
      

.btn-philosophy-more
  text-align center
  padding-top 30px

.btn-recruit-more
  clear both
  text-align center
  padding-top 40px

</style>
