<template>
  <router-view/>
</template>

<style lang="stylus">
@import "@/assets/styles/sanitize.css"
#app
  font-family YuGothic,
    "Yu Gothic Medium",
    "Yu Gothic",
    "Helvetica Neue",
    Arial,
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    "BIZ UDPGothic",
    Meiryo,
    sans-serif
  
.content
  clear both
  
</style>
