import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { plugin, defaultConfig } from '@formkit/vue'
import { ja } from '@formkit/i18n'

const app = createApp(App);
app.use(store).use(router).use(plugin, defaultConfig({
    locales: { ja },
    locale: 'ja',
})).mount('#app')
