<template>
  <footer>
    <div class="info-container">
      <ContactButton class="btn-contact" :style="{ display: display }" />
      <ul class="info-content">
        <li>
          <h2>
            <img src="../../assets/images/logo.png" alt="Smart Process" width="263" height="35">      
          </h2>
          <p>株式会社 Smart Process<br>
              〒105 - 7508<br>
              東京都港区海岸1-7-1<br>
              東京ポートシティ竹芝8F</p>
        </li>
        <li>
          <h3>
            <img src="../../assets/images/footer/nav-tit-service.png" alt="SERVICE" width="63" height="25">
          </h3>
          <ul>
            <li>
              <router-link to="/service/digital">
                >  開発デジタル化戦略企画
              </router-link>
            </li>
            <li>
              <router-link to="/service/process">
                >  開発プロセス設計
              </router-link>
            </li>
            <!--  
            <li>
              <router-link to="/service/simulation">
                >  シミュレーションプロセス設計
              </router-link>
            </li>
            -->
            <li>
              <router-link to="/service/innovation">
                >  イノベーションプロセス展開
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <h3>
              <img src="../../assets/images/footer/nav-tit-recruit.png" alt="RECRUIT" width="64" height="25">
            </h3>
            <li>
              <router-link to="/recruit#digital">
                >  開発デジタル化戦略企画コンサルタント
              </router-link>
            </li>
            <li>
              <router-link to="/recruit#process">
                >  開発プロセス設計エンジニア
              </router-link>
            </li>
            <!--  
            <li>
              <router-link to="/recruit#simulation">
                >  シミュレーションプロセス設計エンジニア
              </router-link>
            </li>
            -->
          </ul>
        </li>
      </ul>
    </div>
    <div class="SNS-title" id="SNS">
      <SNS-txt>
        &nbsp;Smart Process 公式SNSアカウント
      </SNS-txt>
    </div>
    <div class="SNS-box">
      <li>
        <a href="https://www.facebook.com/people/%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BESmart-Process/100087517794324/">
          <img src="../../assets/images/footer/icon_facebook.png" alt="FACEBOOK" width="45" height="45">
        </a>
      </li> 
      <li>
        <a href="https://www.linkedin.com/company/%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BEsmart-process/">
          <img src="../../assets/images/footer/icon_Linkedin.png" alt="LINKEDIN" width="45" height="45">
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCSKBQ7TzzDtTrZpmgPTNudw">
          <img src="../../assets/images/footer/icon_youtube.png" alt="YOUTUBE" width="45" height="45">
        </a>
      </li>
      <li>
        <a href="http://blog.smart-process.co.jp/wp/">
          <img src="../../assets/images/footer/icon_blog.png" alt="BlOG" width="45" height="45">
        </a>
      </li>  
    </div>
    <small>© Smart Process Co.,Ltd.</small>
    <a href="#" class="gotop"  v-show="visible1"></a>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ContactButton from '@/components/common/ContactButton.vue';

export default defineComponent({
  components: {
    ContactButton,
  },
  props: {
    display: {
      type: String,
      default: "block"
    },
  }
  ,data() {
    return {
      visible1: false,

    };
  },
  created() {
     window.addEventListener("scroll", this.handleScroll);
   },beforeUnmount: function () {
     window.addEventListener("scroll", this.handleScroll);
   },
   methods: {
        handleScroll() {
          if (!this.visible1) {
            if(window.scrollY > 200){
              this.visible1 = true
            }
            
          } else if (window.scrollY < 190) {
            this.visible1 = !this.visible1;
          }
        }
      }
});
</script>

<style scoped lang="stylus">
.gotop{
  display: block;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  background: #FFF;
  border: 1px solid #999;
  padding-top: 30px;
  text-align: center;
  letter-spacing: -1px;
  font-size: 85%;
  text-decoration: none;
  color: #333;
  opacity: 0.5;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10000;
}
.gotop::before{
  content: "";
  display: block;
  border-top: 2px solid #333;
  border-right: 2px solid #333;
  width: 25%;
  height: 25%;
  top: 43%;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  transform: rotate(-45deg);
}
.gotop:hover{
  opacity: 1;
}
@media(max-width:750px){
  .gotop{
      width: 40px;
      height: 40px;
      text-indent: -9999px;
      opacity: 1;
      border: none;
      background: none;
      bottom: 10px;
      right: 10px;
  }
  .gotop::before{
      bottom: 0;
  }
}
footer
  background-color #EFEFEF

.info-container
  width 940px
  padding 20px 20px 10px
  margin 0 auto
  overflow hidden

.btn-contact
  width 900px
  margin 0 auto 60px

ul
  padding-inline-start 0

  li
    list-style none

.info-content
  margin-block-start: 0;
  margin-block-end: 0;
  > li
    float left
    &:nth-child(1),
    &:nth-child(2)
      margin-right 80px

p
  font-size 13px
  line-height 2
a
  text-decoration none
  color #000
  font-size 13px
  line-height 2
  opacity 1
  transition all .1s ease-out
  &:hover
    opacity 0.5
    
small
  display block
  clear both
  text-align right
  padding 0 30px 30px 0
  font-size 11px

.SNS-title
  display float
  clear both
  text-align left
  width 500px
  margin  5px auto 5px auto
  text-align:center

SNS-txt
  font-size 15px
  line-height 2

.SNS-box
  width 300px
  height 50px
  margin 0px auto
  

.SNS-box li
  float left
  line-height 50px
  list-style none
  margin 0px 15px 20px 15px

</style>
