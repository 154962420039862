<template>
  <header>
    <h1>
      <router-link to="/">
        <img src="../../assets/images/logo.png" alt="Smart Process" width="263" height="35">      
      </router-link>
    </h1>
    <nav>
      <ul>
        <li class="home">
          <router-link to="/">
            <img src="../../assets/images/header/home.png" alt="HOME" width="" height="16">      
          </router-link>
        </li>
        <li>
          <router-link to="/about">
            <img src="../../assets/images/header/about.png" alt="ABOUT" width="" height="16">      
          </router-link>
        </li>
        <li class="service-nav" v-on:mouseover="showSubmenu">
          <img src="../../assets/images/header/service.png" alt="SERVICE" width="" height="16">
        </li>
        <li>
          <router-link to="/sustainability">
            <img src="../../assets/images/header/sustainability.png" alt="SUSTAINABILITY" width="" height="20">      
          </router-link>
        </li>
        <li>
          <router-link to="/activity">
            <img src="../../assets/images/header/activity.png" alt="ACTIVITY" width="" height="20">      
          </router-link>
        </li>
        <li>
          <router-link to="/recruit">
            <img src="../../assets/images/header/recruit.png" alt="RECRUIT" width="" height="16">      
          </router-link>
        </li>
        <li>
          <router-link to="/contact">
            <img src="../../assets/images/header/contact.png" alt="CONTACT" width="" height="16">      
          </router-link>
        </li>
        <!--
        <li>
          <router-link to="/sustainability">
            <img src="../../assets/images/header/sustainability.png" alt="SUSTAINABILITY" width="110" height="19">      
          </router-link>
        </li>
        <li>
          <a href="https://www.facebook.com/people/%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BESmart-Process/100087517794324/">
            <img src="../../assets/images/header/btn-facebook.png" alt="FACEBOOK" width="70" height="16.5">      
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BEsmart-process/">
            <img src="../../assets/images/header/btn-linkedin.png" alt="LINKEDIN" width="65" height="16.5">      
          </a>
        </li> -->
        
        <!-- <li>
          <router-link to="/seminar">
            <img src="../../assets/images/header/btn-seminar.png" alt="SEMINAR" width="62" height="16">      
          </router-link>
        </li>
         -->
        
      </ul>
      <transition name="fade">
        <div v-if="isShowSubmenu" v-on:mouseleave="hideSubmenu" class="service-submenu-wrapper">
          <div class="service-bg"></div>
          <div class="service-submenu">
            <!-- <img src="../../assets/images/header/service_on.png" alt="SERVICE" width="58" height="16"> -->
            <router-link to="/service/digital">
              ＞ 開発デジタル化戦略企画
            </router-link>
            <router-link to="/service/process">
              ＞ 開発プロセス設計
            </router-link>
            <!-- 
            <router-link to="/service/simulation">
              ＞ シミュレーションプロセス設計
            </router-link>
             -->
            <router-link to="/service/innovation">
              ＞ イノベーションプロセス展開
            </router-link>
          </div>
        </div>
      </transition>
    </nav>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  setup() {
    const show = ref<boolean>(false);
    const isShowSubmenu = ref<boolean>(false);
    const showSubmenu = () => {
      isShowSubmenu.value = true;
    }

    const hideSubmenu = () => {
      isShowSubmenu.value = false;
    }

    return {
      showSubmenu,
      hideSubmenu,
      isShowSubmenu,
      show
    }
  }
});
</script>

<style scoped lang="stylus">
header
  position relative
  height 90px
  padding 30px
h1
  float left
  line-height 0
  margin -3px 0 0 0

nav
  float right
  font-size 0
  margin-top 8px
  .fade-enter-active,
  .fade-leave-active
    transition opacity 0.3s ease

  .fade-enter-from,
  .fade-leave-active
    opacity 0
  li
    float left
    margin-left 25px
  .service-nav
    cursor pointer
    img
      &:hover
        opacity 50
        

  .service-submenu-wrapper
    position relative
    z-index 10

    .service-bg,
    .service-submenu
      position absolute
      top 52px
      left 144px
      width 300px
      height 130px

    .service-bg
      background-color #000
      opacity 0.5

    .service-submenu
      padding 17px 0 0 17px
      > img
        display block
        margin-bottom 30px
      a
        display block
        font-size 16px
        color #fff
        font-weight bold
        text-decoration none
        margin-bottom 10px
a
  opacity 1
  transition all .1s ease-out
  &:hover
    opacity 0.5
</style>
