<template>
  <div class="btn-contact">
    <router-link to="/contact">
      <img src="../../assets/images/btn-txt-contact.png" alt="CONTACT" width="120" height="40">
      <img class="arrow" src="../../assets/images/ico-arrow.png" alt="" width="33" height="7">
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
});
</script>

<style scoped lang="stylus">
.btn-contact
  a
    display block
    border 2px solid #000
    text-align center
    background-color #fff
    padding 20px 0
    opacity 1
    transition all .1s ease-out

  .arrow
      display inline-block
      margin -7px 0 0 10px
      transition all .1s ease-out

    &:hover
      opacity 0.5
      .arrow
        transform translate(2px, 0)
</style>
